import React        from 'react';
import PropTypes    from 'prop-types';
import Flux         from '../../flux/Flux';
import LogoLoader   from "./LogoLoader";
import DotLoader    from './DotLoader';
import CircleLoader from './CircleLoader';

const TYPES = Flux.Constants.Loader.Types;

class Loader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type: this.props.type,
        };

        this.onLoaderChange = this.onLoaderChange.bind(this);
    }

    componentDidMount() {
        Flux.Special.addShowLoaderChangeListener(this.onLoaderChange);
    }

    componentWillUnmount() {
        Flux.Special.removeShowLoaderChangeListener(this.onLoaderChange);
    }

    onLoaderChange(type) {
        this.setState({type});
    }

    render() {
        let loader = null;
        switch(this.state.type) {
            case TYPES.LOGO:
                loader = <LogoLoader className="-static" pulse={true} />;
                break;
            case TYPES.DOT:
                loader = <DotLoader />;
                break;
            case TYPES.CIRCLE:
                loader = <CircleLoader />;
                break;
        }
        return (
            this.state.type !== TYPES.NONE && <div className={"loader__wrapper " + this.props.additionalClass}>
                {loader}
            </div>);
    }
}
/** 
 * Types: {
			LOGO:   'logo',
			DOT:    'dot',
			CIRCLE: 'circle',
			NONE:   'none'
		}
**/
Loader.propTypes    = {
    type:            PropTypes.oneOf([
        TYPES.LOGO,
        TYPES.DOT,
        TYPES.CIRCLE,
        TYPES.NONE
    ]),
    fullscreen:      PropTypes.bool,
    additionalClass: PropTypes.string,

};
Loader.defaultProps = {
    type:            TYPES.NONE,
    fullscreen:      true,
    additionalClass: '',
};

export default Loader;

export {TYPES};