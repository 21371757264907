import React     from 'react';
import PropTypes from 'prop-types';

class DotLoader extends React.Component {

	render() {
		return(
			<div className={"dot-loader"}>
				<div className="dot-loader__dot dot-loader__dot--1"></div>
				<div className="dot-loader__dot dot-loader__dot--2"></div>
				<div className="dot-loader__dot dot-loader__dot--3"></div>
			</div>
		);
	}
}

DotLoader.propTypes = {
	className: PropTypes.string,
};

DotLoader.defaultProps = {
	className: '',
};

export default DotLoader;
